<template>
  <div>
    <div class="content_main">
      <div class="template_type">
        <!-- <span>{{typeList[Number(activeTab)]}}</span> -->
        <span>制作答题卡</span>
        <div class="btn_wrap">
          <!-- <div class="btn"
               v-if="showApplyStatus.includes(form.status)"
               @click="apply">申请制作答题卡</div>
          <div class="btn"
               v-if="form.status == 4"
               @click="cancelApply">取消申请</div> -->
          <div class="btn"
               @click="openFile('https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/shanli_dtk_standard_district_template.pdf')">查看制作规范</div>
        </div>
      </div>
      <el-steps direction="vertical"
                :active="activeStep">
        <el-step title="第一步 选择答题卡类型 ">
          <template slot="description">
            <div class="box step">
              <el-radio-group v-model="size"
                              @change="changeSize">
                <el-radio :label="1">A4答题卡</el-radio>
                <el-button style="margin-bottom:10px"
                           @click="openFile(sizeData[1].url)">导出模板</el-button><br>
                <el-radio :label="0">A3答题卡</el-radio>
                <el-button @click="openFile(sizeData[0].url)">导出模板</el-button>
              </el-radio-group>
            </div>
          </template>
        </el-step>

        <el-step title="第二步 上传制作好的答题卡（必须上传word文件和pdf文件）">
          <template slot="description">
            <div class="box step">
              <div v-show="activeStep>1">
                <FileSteps @importSubmit="importSubmit"
                           @resetFile="resetFile"
                           :isEdit="isEdit"
                           ref="fileRef" />
              </div>

            </div>
          </template>

        </el-step>
        <el-step title="第三步 标记答题卡正面">
          <template slot="description">
            <div class="box step">
              <div v-show="activeStep>2">
                <FrontCard :form="form"
                           ref="sheetFrontRef"
                           :signAbled="signAbled"
                           :size="size" />
              </div>

            </div>

          </template>
        </el-step>
        <el-step title="第四步 标记答题卡反面">
          <template slot="description">
            <div class="box step">
              <div v-show="activeStep>2">
                <BackCard :form="form"
                          ref="sheetBackRef"
                          :signAbled="signAbled&&activeStep>3"
                          :size="size" />
              </div>

            </div>

          </template>
        </el-step>
        <el-step title="第五步 测试标记的答题卡">
          <template slot="description">
            <div class="box step">
              <div v-show="activeStep>4">
                <el-button type="primary"
                           v-if="form.excel_image_two && form.excel_image_one"
                           @click="testCard">
                  测试答题卡
                </el-button>
              </div>
            </div>
          </template>
        </el-step>
      </el-steps>

      <el-row style="margin-top:30px">

        <el-button type="primary"
                   v-if="form.excel_image_two || form.excel_image_one"
                   @click="save">
          保存答题卡
        </el-button>
        <el-button type="danger"
                   v-show="activeStep>4"
                   v-if="form.excel_image_two && form.excel_image_one"
                   @click="submit">
          提交答题卡
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { uploadImageAuto, uploadExcelAuto, uploadExcel } from '@/api/upload.js'
import { points, points1, pointsA4, points1A4 } from './components/pointList.js'


import Sheet from './components/sheet.vue'
// import FileDialog from './components/fileDialog.vue'
import FileSteps from './components/steps/uploadFile.vue'
import FrontCard from './components/steps/FrontCard.vue'
import BackCard from './components/steps/BackCard.vue'

// import TypeTip from './components/typeTip.vue'
import { getTestCardInfo } from '@/api/sheet.js'


import { saveSheet, continueMake, applyMake, cancelApplyMake, getSheetTemplate } from '@/api/sheet.js'
import Vue from 'vue'
export default {
  data () {
    return {
      activeTab: "2",//当前面板，默认自制模板
      // typeList: ["通用模板", "推荐模板", "自制模板"],

      showApplyStatus: [0, -1, ''], // 当答题卡状态为以下内容时，才显示申请按钮

      form: {
        pdf_file: '',
        // 教师试卷ID
        edu_paper_id: '',
        // 上传的excel 文件url地址
        excel_file: '',
        // 正面图片地址
        excel_image_one: '',
        // 反面图片地址
        excel_image_two: '',
        // 正面标注图片上传后地址
        mark_image_one: '',
        // 反面标注图片上传后地址
        mark_image_two: '',
        // 正面标注图片标注后json 数据
        mark_image_one_json: JSON.stringify(points),
        // 反面标注图片标注后json 数据
        mark_image_two_json: JSON.stringify(points1),
        // 教师试卷答题卡ID 修改必传
        sheet_id: '',
        status: '',
      },

      signAbled: true,//是否可以标注
      isEdit: false,

      templateData: [],//推荐模板

      sizeData: [
        { size: "A3", url: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_A3.docx', url2: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_correct_A3.docx' },
        { size: "A4", url: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_A4.docx', url2: 'https://sunraydtk.oss-cn-beijing.aliyuncs.com/temp/common_template_correct_A4.docx' }
      ],

      hasSelectId: '',
      size: '',
      // type: "", //模板类型
      fileList: {
        word: undefined,
        excel: undefined,
        pdf: undefined
      },
      activeStep1: 1 //辅助步骤activeStep
    }
  },
  computed: {
    activeStep: {
      get () {
        if (this.check(this.form, '2')) {
          return 5
        }
        else if (this.check(this.form, '1')) {
          return 4
        }
        else if (this.activeStep1 == 3) {
          return 3
        }
        else if (this.size || this.size === 0) {
          return 2
        } else {
          return 1
        }
      },
      set (val) {
        return val
      }
    }
  },
  components: {
    Sheet,
    // FileDialog,
    FileSteps,
    // TypeTip,
    FrontCard,
    BackCard
  },

  async mounted () {

    await this.getSheetTemplate()
    let sheet_id = this.$route.query.sheet_id
    if (Number(sheet_id)) {
      this.countinueMakeSheet(sheet_id)
    }
    // else if (this.$route.query.type == 'autonomously') {
    //   this.showTypeDialog()
    // }
    else {
      this.activeTab = 2
    }
    // this.type = this.$route.query.type

    let status = this.$route.query.status
    if (Number(status)) {
      this.form.status = status
    }
  },
  methods: {
    //选择模式类型，弹窗提交执行
    // changeType (type) {
    //   this.resetForm()
    //   this.activeTab = type
    //   // this.$refs.typeTipRef.dialogVisible = false
    // },

    // 选择a3/a4 清空文件
    changeSize () {
      this.$refs.fileRef.clear('word')
      this.$refs.fileRef.clear('pdf')
      this.resetFile()
    },
    resetFile () {
      this.activeStep1 = 2
      this.resetForm()

    },
    // 选择模板类型（自制，推荐）（下线）
    // showTypeDialog () {
    //   // this.$refs.typeTipRef.pageType = this.$route.query.type //页面是否为自主组卷页面，其他无推荐模板
    //   // this.$refs.typeTipRef.templateLen = this.templateData.length
    //   // this.$refs.typeTipRef.dialogVisible = true
    // },

    // 根据试卷id筛选答题卡模板
    async getSheetTemplate () {
      const { data } = await getSheetTemplate({
        edu_paper_id: Number(this.$route.query.id)
      })
      this.templateData = data
    },
    // 申请制作答题卡
    async apply () {
      let data = {
        edu_paper_id: this.$route.query.id
      }
      await applyMake(data)
      this.$message.success("已提交申请")
    },
    // 取消申请制作答题卡
    async cancelApply () {
      let data = {
        edu_paper_id: this.$route.query.id
      }
      await cancelApplyMake(data)
      this.$message.success("已提交取消申请")
    },

    async countinueMakeSheet (id) {
      let params = {
        sheet_id: id
      }
      const { data } = await continueMake(params)
      this.form = JSON.parse(JSON.stringify(data))
      this.size = this.form.size
      this.activeTab = this.form.type
      if (this.activeTab == 0) { //原来是通用模板的现在都为自制模板
        this.activeTab = 2
      }
      if (this.form.paper_sheet_id) {
        this.hasSelectId = this.form.paper_sheet_id
        // this.activeTab = "1"
        this.signAbled = false
      }

      console.log(JSON.parse(data.mark_image_one_json))
      this.$nextTick(() => {
        this.$refs.sheetFrontRef.getFixedImage()
        this.$refs.sheetBackRef.getFixedImage()
        this.activeStep1 = 3
        this.isEdit = true
      })
    },


    // // 直接使用模板
    // useTemplate (index, row) {
    //   this.hasSelectId = row.sheet_id
    //   this.signAbled = false
    //   this.size = row.size
    //   let rowtemp = JSON.parse(JSON.stringify(row))
    //   if (JSON.parse(rowtemp.mark_image_one_json).length > 0) {
    //     this.form = rowtemp
    //   } else {
    //     rowtemp.mark_image_one_json = JSON.stringify(points)
    //     rowtemp.mark_image_two_json = JSON.stringify(points1)
    //     this.form = rowtemp
    //   }
    //   this.form.sheet_id = this.$route.query.sheet_id
    //   // this.$refs.sheetRef.getFixedImage()
    //   console.log("form", this.form)
    // },
    // // 取消使用模板
    // cancelUseTemplate () {
    //   this.hasSelectId = ''
    //   this.signAbled = true
    //   this.size = 0
    //   this.resetForm()
    // },


    resetForm () {
      this.hasSelectId = ''
      let status = this.form.status
      this.form = {
        pdf_file: '',
        // 教师试卷ID
        edu_paper_id: '',
        // 上传的excel 文件url地址
        excel_file: '',
        // 正面图片地址
        excel_image_one: '',
        // 反面图片地址
        excel_image_two: '',
        // 正面标注图片上传后地址
        mark_image_one: '',
        // 反面标注图片上传后地址
        mark_image_two: '',
        // 正面标注图片标注后json 数据
        mark_image_one_json: JSON.stringify(points),
        // 反面标注图片标注后json 数据
        mark_image_two_json: JSON.stringify(points1),
        // 教师试卷答题卡ID 修改必传
        sheet_id: '',
        status: '',
      }
      this.form.status = status
    },



    async importSubmit (fileList) {
      let formData = new FormData()
      if (fileList.excel) {
        formData.append("filename", "excel")
        formData.append("excel", fileList.excel)
      } else if (fileList.word) {
        formData.append("word_file_name", "word")
        formData.append("word", fileList.word)
      }
      formData.append("pdf_file_name", "pdf")
      formData.append("pdf", fileList.pdf)
      formData.append("edu_paper_id", Number(this.$route.query.id))
      formData.append('sign', Vue.prototype.$getMd5Form({ edu_paper_id: this.$route.query.id, pdf_file_name: 'pdf', word_file_name: 'word' }))

      const { data } = await uploadExcel(formData)
      // this.$refs.fileRef.reset()
      // this.$refs.fileRef.handleClose()

      this.form.excel_file = data.excel_thumb_path
      this.form.word_file = data.word_thumb_path
      this.form.pdf_file = data.pdf_thumb_path
      this.form.excel_image_one = data.image_data["01"]
      this.form.excel_image_two = data.image_data["02"]
      if (this.size === 0) {
        // A3
        // 正面标注图片标注后json 数据
        this.form.mark_image_one_json = JSON.stringify(points)
        // 反面标注图片标注后json 数据
        this.form.mark_image_two_json = JSON.stringify(points1)
      } else if (this.size == 1) {
        // A4
        this.form.mark_image_one_json = JSON.stringify(pointsA4)
        this.form.mark_image_two_json = JSON.stringify(points1A4)
      }

      this.$nextTick(() => {
        this.$refs.sheetFrontRef.getFixedImage()
        this.$refs.sheetBackRef.getFixedImage()
        this.activeStep1 = 3
      })
    },

    // importDialog (type) {
    //   this.$refs.fileRef.dialogVisible = true
    //   this.$refs.fileRef.fileType = type
    // },

    openFile (url) {
      // console.log(url)
      window.open(url)
    },
    check (form, page) {
      // page 1正面，0反面
      let zheng = JSON.parse(form.mark_image_one_json)
      let fan = JSON.parse(form.mark_image_two_json)

      let bool = true
      let arr = ['w', 'h']
      // 判断正面已标注
      if (page == '1') {
        arr.forEach(b => {
          if (zheng[0].points[b] == '') {
            bool = false
          }
        })
        if (bool) {
          this.activeStep = 4
        }
        return bool
      }
      // 判断反面面已标注
      if (page == '2') {
        arr.forEach(b => {
          if (fan[0].points[b] == '') {
            bool = false
          }
        })
        if (bool) {
          this.activeStep = 5
        }
        return bool
      }

      // 同时正反面
      arr.forEach(b => {
        if (zheng[0].points[b] == '' || fan[0].points[b] == '') {
          bool = false
        }
      })
      return bool
    },


    //正反面都有可提交
    async submit () {
      let form = JSON.parse(JSON.stringify(this.form))
      if (!this.check(form)) {
        this.$message.warning("请标记完整答题卡正反面")
        return
      }
      form.edu_paper_id = this.$route.query.id
      form.keep_id = 0
      form.size = this.size
      form.type = Number(this.activeTab)
      form.correct_type = this.$route.query.correct_type

      if (this.hasSelectId) {
        form.paper_sheet_id = this.hasSelectId
      }
      await saveSheet(form)
      this.$message.success("操作成功")
      this.$router.go(-1)
    },
    //只有其中一面，保存
    async save () {
      let form = JSON.parse(JSON.stringify(this.form))
      form.edu_paper_id = this.$route.query.id
      form.keep_id = 1
      form.size = this.size
      form.type = Number(this.activeTab)
      form.correct_type = Number(this.$route.query.correct_type)

      if (this.hasSelectId) {
        form.paper_sheet_id = this.hasSelectId
      }
      const { data } = await saveSheet(form)
      this.$message.success("操作成功")
      if (this.$route.query.sheet_id == 0) {
        // 初次创建返回sheet_id
        // this.$router.push(`/testPapercomposition/templateCard?id=${this.$route.query.id}&sheet_id=${data.sheet_id}&status=${this.$route.query.status}&correct_type=${this.$route.query.correct_type}&type=${this.$route.query.type}`)
        this.$router.go(-1)
      } else {
        this.$router.go(-1)
      }

    },
    // 测试答题卡
    async testCard () {
      let form = {
        url1: this.form.excel_image_one,
        url2: this.form.excel_image_two,
        mark_image_one_json: this.form.mark_image_one_json,
        mark_image_two_json: this.form.mark_image_two_json,
        type: this.size,
        // correct_type: this.$route.query.correct_type,
        edu_paper_id: this.form.edu_paper_id,

      }
      // window.sessionStorage.setItem("testCard", JSON.stringify(form))
      // this.$router.push('/exaManage/reviewSheet?isTest=' + true)
      try {
        const { data } = await getTestCardInfo(form)
        this.$message.success("测试成功")
      } catch (err) {
        this.$message.error("测试失败")
      }
      console.log(form)
    },

  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 10px;
}
.btn {
  padding: 10px 20px;
  background: #409eff;
  border-radius: 4px;
  color: white;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.btn_wrap {
  display: flex;
}

::v-deep .el-message--warning {
  z-index: 2001 !important;
}
.content_main {
  font-size: 14px;
  padding: 5px 20px 5px 30px;
  .text_btn {
    padding: 2px;
    margin-right: 10px;
  }
}
::v-deep .el-button {
  width: auto;
  padding: 10px 15px;
}
::v-deep .el-tabs__item.is-active {
  font-size: 18px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
}
::v-deep .el-button--medium {
  padding: 10px 20px;
  height: auto;
  font-size: 14px;
  border-radius: 4px;
}
::v-deep .el-radio {
  line-height: 35px;
}
.template_type {
  padding: 0 20px 20px;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:before {
    content: "";
    width: 4px;
    height: 16px;
    border-radius: 20px;
    position: absolute;
    margin-top: -18px;
    left: 0;
    top: 50%;
    background: #48acfd;
    margin-right: 20px;
  }
}
.step {
  color: #333;
  padding: 15px 15px 20px 15px;
}
</style>